<template>
  <div class="text">
    <ion-modal
      :is-open="showModalForIndex > -1"
      @ionModalDidDismiss="
        () => {
          showModalForIndex = -1;
        }
      "
      @ionModalDidPresent="onModalPresent"
      :show-backdrop="true"
      class="select-modal"
      ref="pickerModal"
    >
      <div class="select-modal-content bg-white w-100">
        <ion-list class="scroll">
          <ion-item @click="openSubscribeModal($event, 'worldAddSection')" class="option">
            <PlusTag size="md" class="mr-2" /> <strong class="no-select">See 100+ more trait ideas</strong>
          </ion-item>
          <div v-for="(value, key) of worldAboutKeys" :key="key">
            <div v-if="showCategory(key)">
              <ion-item v-if="toTitleSpaceCase(String(key))">
                <ion-label class="pt-4">
                  <span class="bold trait-cat-title no-select">{{ toTitleSpaceCase(String(key)) }}</span>
                </ion-label>
              </ion-item>
              <div v-for="(item, inIndex) of value" :key="inIndex">
                <ion-item
                  v-if="showKey(item)"
                  class="no-select option"
                  :class="{ bold: item === 'custom' }"
                  :value="item"
                  :disabled="keyIsInUse(item)"
                  @click="optionSelected(item)"
                >
                  <span :id="'p_' + item">{{ toTitleSpaceCase(item) }}</span>
                  <i v-if="keyIsInUse(item)" class="ti-check mr-3" slot="end" />
                </ion-item>
              </div>
            </div>
          </div>
        </ion-list>
        <ion-footer class="ion-no-border">
          <ion-toolbar class="tbottom">
            <ion-button class="close-btn" size="large" expand="block" @click="showModalForIndex = -1">
              <span class="no-select">Close</span>
            </ion-button>
          </ion-toolbar>
        </ion-footer>
      </div>
    </ion-modal>

    <ion-reorder-group :disabled="false" @ionItemReorder="handleReorder($event)">
      <div v-for="(element, index) of worldAbout" :key="element.id" class="d-flex align-items-center mt-2 one-row">
        <ion-reorder slot="end" class="reorder-desktop">
          <i id="about-drag-handle" class="ti-menu clickable-item-hov mx-2 about-drag-icon" />
        </ion-reorder>
        <ReorderMobile :index="index" :total="worldAbout.length" class="reorder-mobile" @reorder="handleReorder" />
        <div class="data d-flex">
          <ion-button class="mr-2 mt-0 selector" @click="showModalForIndex = index" color="light">
            <span class="key-button no-select selector-text"
              ><span style="font-size: 1.1rem; user-select: none !important">▾</span
              >{{ element.isCustom ? 'Custom' : toTitleSpaceCase(element.key) }}</span
            >
          </ion-button>
          <div class="rest d-flex flex-grow-1">
            <ion-input
              v-if="element.isCustom"
              class="c-input about-key mr-2"
              :value="element.key?.replace(delimitingCharacterRegex, '')"
              placeholder="Field Title"
              @ionInput="debouncedCommitAboutValueCustomKey($event, index)"
            />
            <ion-textarea
              v-model="element.value"
              :class="{ 'w-100': !element.isCustom }"
              class="c-textarea mr-2 flex-grow-1 details-text"
              placeholder="Details"
              @ionInput="debouncedCommitAboutValue($event, index)"
            />
          </div>
        </div>

        <ion-button class="inline-button clickable-item-hov" color="transparent" @click="deleteAboutField(element.key)">
          <i class="ti-trash" />
        </ion-button>
      </div>
    </ion-reorder-group>
    <ion-custom-button class="add-btn text-white w-100 mt-2" @click.self="addAboutField">Add More</ion-custom-button>
  </div>
</template>

<script lang="ts" setup>
import { nanoid } from 'nanoid';
import ReorderMobile from './ReorderMobile.vue';
import { storyWorldCreatorStore } from '@/shared/pinia-store/storyworld-creator';
import { toTitleSpaceCase } from '@/shared/utils/string';
import { openSubscribeModal } from '@/shared/utils/modals';
import {
  delimitingCharacter,
  delimitingCharacterRegex,
  worldAboutKeys,
  worldAboutPremiumCategories,
  worldAboutPremiumKeys,
} from '@/shared/statics/constants';
import { authStore } from '@/shared/pinia-store/auth';
import { featureFlags } from '@/shared/config/feature-flags';

const { worldAbout, setWorldAbout } = storyWorldCreatorStore();
const { userPro } = authStore();
const showModalForIndex = ref(-1);
const pickerModal = ref<HTMLElement | null>(null);

defineProps({
  usedKey: {
    type: String,
    default: '',
  },
});

const optionSelected = (selectedKey: string) => {
  const about = worldAbout.value;
  if (selectedKey === 'custom' && about[showModalForIndex.value].key.startsWith('custom')) return;
  if (about.map(({ key }) => key).includes(selectedKey) && selectedKey !== 'custom') return;

  // get the number of empty custom fields already in about
  const emptyCustomFieldCount = about.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
  about[showModalForIndex.value].key =
    selectedKey === 'custom' ? delimitingCharacter.repeat(emptyCustomFieldCount) : selectedKey;
  setWorldAbout(about);
  showModalForIndex.value = -1;
};

const onModalPresent = () => {
  if (showModalForIndex.value === -1) return;
  const document = useDocument();
  if (pickerModal) {
    const key = worldAbout.value[showModalForIndex.value].key
    const selectedOption = document.value.getElementById('p_' + key);
    if (selectedOption) {
      selectedOption.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }
}

const aboutKeysInUse = computed(() => worldAbout.value.map(({ key }) => key));
const keyIsInUse = (key: string) => aboutKeysInUse.value.includes(key);

const showKey = (key: string) => {
  const inPremium = worldAboutPremiumKeys.includes(key);

  if (!inPremium) return true;

  if (!featureFlags.pro && inPremium) return false;

  if (inPremium && userPro.value.moreabout) return true;
};

const showCategory = (cat: string) => {
  const inPremium = worldAboutPremiumCategories.includes(cat);

  if (!inPremium) return true;

  if (!featureFlags.pro && inPremium) return false;

  if (inPremium && userPro.value.moreabout) return true;
};

const aboutKeyChanged = (index: number, ev: CustomEvent) => {
  const selectedKey = ev.detail.value;

  if (worldAbout.value.map(({ key }) => key).includes(selectedKey) && selectedKey !== 'custom') return;

  const about = worldAbout.value;
  // get the number of empty custom fields already in about
  const emptyCustomFieldCount = about.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
  about[index].key = selectedKey === 'custom' ? delimitingCharacter.repeat(emptyCustomFieldCount) : selectedKey;
  setWorldAbout(about);
};

const deleteAboutField = (key: string) => {
  setWorldAbout(worldAbout.value.filter((item) => item.key !== key));
};
const freeWorldAboutKeys = computed(() => {
  return omit(worldAboutKeys, worldAboutPremiumCategories);
});
const aboutKeys = computed(() => worldAbout.value.map(({ key }) => key));
const allKeys = flatten(Object.values(worldAboutKeys));
const freeMainKeys = flatten(Object.values(freeWorldAboutKeys.value));

const nextKey = computed(() => {
  const freekeys = freeMainKeys.filter((key) => !worldAboutPremiumKeys.includes(key));
  const keys = (userPro.value.moreabout ? allKeys : freekeys).filter((key) => !aboutKeys.value.includes(key));
  return keys.length > 1 ? keys[1] : '';
});

const addAboutField = () => {
  worldAbout.value.push({ id: nanoid(8), key: nextKey.value, value: '' });
  setWorldAbout(worldAbout.value);
};

const commitAboutValue = (ev: CustomEvent, index: number) => {
  const mutatedAbout = worldAbout.value;
  mutatedAbout[index].value = ev.detail.value || '';
  setWorldAbout(mutatedAbout);
};

const debouncedCommitAboutValue = debounce(commitAboutValue, 300);

const commitAboutValueCustomKey = (ev: CustomEvent, index: number) => {
  const mutatedAbout = worldAbout.value;
  const newKey = ev.detail.value.replace(delimitingCharacterRegex, '');
  const about = worldAbout.value;
  const selectedKey = ev.detail.value;
  if (newKey) {
    mutatedAbout[index].key = newKey.replace(delimitingCharacterRegex, '');
  } else {
    // get the number of empty custom fields already in about
    const emptyCustomFieldCount = about.filter((item) => !item.key.replace(delimitingCharacterRegex, '')).length;
    let key = selectedKey === 'custom' ? delimitingCharacter.repeat(emptyCustomFieldCount) : selectedKey;
    while (about.includes(key)) {
      key += delimitingCharacter;
    }
    mutatedAbout[index].key = key;
  }
  setWorldAbout(mutatedAbout);
};

const debouncedCommitAboutValueCustomKey = debounce(commitAboutValueCustomKey, 300);

const handleReorder = (ev: CustomEvent) => {
  const { from, to } = ev.detail;
  const mutatedAbout = worldAbout.value;
  mutatedAbout.splice(to, 0, mutatedAbout.splice(from, 1)[0]);
  setWorldAbout(mutatedAbout);
  try {
    ev.detail.complete();
    ev.stopPropagation();
  } catch (e) {}
};
</script>

<style lang="sass" scoped>
.close-btn
  width: 90%
  left: 0
  right: 0
  margin: auto
  margin-bottom: 0.25rem
.selector
  width: 150px
  overflow-x: hidden
  text-overflow: ellipsis
  padding-inline-start: 0 !important
.selector-text
  white-space: nowrap !important
  overflow: hidden
  text-overflow: ellipsis
.about-key
  width: 150px !important
.reorder-mobile
  display: none !important
.reorder-desktop
  display: block
  i
    cursor: move !important
    -webkit-user-drag: element
@media(max-width: 500px)
  .reorder-desktop
    display: none !important
  .reorder-mobile
    display: block !important
  .selector
    width: unset
    margin-right: unset !important
  .details-text
    width: 100% !important
.one-row
  &:not(:first-of-type)
    margin-top: 20px !important
.data
  width: 100%

@media(max-width: 500px)
  .rest
    width: 100% !important
    flex-direction: column !important
  .c-input
    &:last-of-type
      margin-right: 0 !important
  .data
    width: 100%
    flex-direction: column !important
    .c-select, .c-input
      width: 100% !important
      margin-top: 5px
.add-btn
  margin-left: 32px
  width: calc(100% - 65px) !important
  ::v-deep
    .button
      height: 30px !important
      text-transform: unset !important
.c-input, .c-select, .c-textarea
  height: 42px !important
  border-radius: 8px !important
  border: 1px solid #ccc !important
  overflow: hidden
  width: unset
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      min-height: unset !important
      height: 42px
      padding: 12px !important
.dark
  .c-input, .c-select, .c-textarea
    border: 1px solid #666 !important
.c-textarea
  ::v-deep
    min-height: unset !important
.one-input
  margin-bottom: 10px
  border-radius: 8px !important
  ::v-deep
    input, textarea
      border: 0 !important
    textarea
      padding: 12px
.scroll
  overflow-y: scroll !important
  height: calc(100vh - 70px - var(--safe-ios-margin) - calc(var(--safe-ios-margin)/3))
  border-bottom-left-radius: 0
  border-bottom-right-radius: 0
.option
  &:hover
    user-select: none !important
    cursor: pointer
    opacity: 0.7
    --background: #eee !important
.dark
  ion-modal
    --ion-item-background: #333538 !important
  .option
    &:hover
      --background: #666 !important
.gray
  color: #aaa
.trait-cat-title
  font-size: 20px
.key-button
  white-space: normal
.select-modal
  --height: 100%
  --width: 100%
  --border-radius: 0
  @media(min-width: 599px) and (min-height: 767px)
    --height: 70%
    --width: 50%
    --border-radius: 12px
.select-modal-content
  display: flex
  flex-direction: column
  height: 100%

  ion-content
    flex: 1
    overflow-y: auto

.dark .tbottom
  :deep()
    --background: #333538 !important
</style>
